const languages = [

    {
        code: 'il',
        name: 'Hebrew',
        lang: 'he'
    },
    {
        code: 'us',
        name: 'English',
        lang: 'en'
    }
]

export default languages